import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Stage } from '../../configuration/stage.enum';
import { affinityGroupsReducer } from '../features/affinity-groups/slices/affinity-groups.slice';
import { profileReducer } from '../features/profile/slices/profile.slice';
import { surveyReducer } from '../features/survey/slices/survey.slice';
import { contentReducer } from '../features/content/slices/content.slice';

const reducer = combineReducers({
    affinityGroups: affinityGroupsReducer,
    profile: profileReducer,
    survey: surveyReducer,
    content: contentReducer,
});

export type RootState = ReturnType<typeof reducer>;

export const store = configureStore({
    reducer,
    devTools: ('prod'.toLowerCase() as Stage) !== Stage.prod,
});
